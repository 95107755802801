import axios from 'axios';

// export const GROUP_ID = 291422;
// export const GROUP_ID = 481710;
export const GROUP_ID = 211908; // main
export const WIDJET_ID = 301687;

const API_PREFIX = "https://api.yclients.com/api/v1/";

const API_BACKEND = 'https://back.cena-slova.com/'
// const API_BACKEND = 'http://localhost:5001'

export const PARTHER_TOKEN = "5kda3hw6us9wkds3neyc";

export const instance = axios.create({
  baseURL: API_PREFIX,
  headers: {
    Accept: "application/vnd.yclients.v2+json",
    Authorization: `Bearer ${PARTHER_TOKEN}`,
  },
});

export const instanceHeroku = axios.create({
  baseURL: 'https://amo-widgets.herokuapp.com/',
});

export const backend_instance = axios.create({
  baseURL: API_BACKEND,
})