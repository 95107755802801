import React from 'react';
// import { FaInstagram /* FaWallet */ } from "react-icons/fa";

import { Box } from '@chakra-ui/layout';
import { Button as ChakraButton } from '@chakra-ui/react';
import { Result, Typography } from 'antd';
import { Routes } from '../../shared/enums/routes';
import { Link } from 'react-router-dom';
import { FaTelegram, FaVk } from 'react-icons/fa';

export const SuccessPage111 = () => {
  return (
    <Box height='100vh' display='flex' alignItems='center' justifyContent='center'>
      <Result
        status='success'
        title='Успешно'
        //subTitle=''

        extra={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography.Title style={{ fontSize: 14, marginTop: 0 }}>
              Спасибо за заявку, позже мы с Вами свяжемся
            </Typography.Title>
            <Typography.Paragraph>
              Подпишитесь на наши социальные сети, чтобы быть в курсе событий и получать полезный контент!
            </Typography.Paragraph>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '180px',
                alignSelf: 'center',
                marginBottom: '24px',
              }}
            >
              <a href='https://t.me/missislaser'>
                <FaTelegram size={28} color='#0088cc' />
              </a>
              <a href='https://vk.com/missis_laser'>
                <FaVk size={28} color='#0077ff' />
              </a>
              <a href='https://www.instagram.com/missis_laser/'>
                <img alt='inst' src='inst.png' style={{ width: '24px' }} />
              </a>
              <a href='https://dzen.ru/missis-laser.ru'>
                <img alt='dzen' src='dzen.png' style={{ width: '24px' }} />
              </a>
            </div>
            <Link to={`${Routes.Preparation}`}>
              <ChakraButton size='sm' colorScheme='green'>
                Как подготовится к процедуре?
              </ChakraButton>
            </Link>
          </div>
        }
      />
    </Box>
  );
};
