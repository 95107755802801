import React from 'react';
// import { FaInstagram /* FaWallet */ } from "react-icons/fa";

import { Box } from '@chakra-ui/layout';
import { Button as ChakraButton } from '@chakra-ui/react';
import { Result } from 'antd';
import { useStore } from '../../stores';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { Routes } from '../../shared/enums/routes';
import { Link } from 'react-router-dom';
import { FaTelegram, FaVk } from 'react-icons/fa';

export const SuccessPage = () => {
  const { registerStore } = useStore();

  React.useEffect(() => {
    const firebaseConfig = {
      apiKey: 'AIzaSyAODnA4EfNRBeGgA4BNoEb3PYJ5TFcgAlY',
      authDomain: 'missis-laser.firebaseapp.com',
      projectId: 'missis-laser',
      storageBucket: 'missis-laser.appspot.com',
      messagingSenderId: '514174044584',
      appId: '1:514174044584:web:3a7576b39c7a543c6ab59d',
      measurementId: 'G-QTNN569ZLV',
    };

    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    logEvent(analytics, 'success_appointment');
  }, [registerStore]);

  return (
    <Box height='100vh' display='flex' alignItems='center' justifyContent='center'>
      <Result
        status='success'
        title='Вы успешно записаны'
        subTitle='За 72 часа до приема Вы получите уведомление на свой мобильный номер'
        extra={
          <div style={{ marginTop: 16, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '180px',
                alignSelf: 'center',
                marginBottom: '24px',
              }}
            >
              <a href='https://t.me/missislaser'>
                <FaTelegram size={28} color='#0088cc' />
              </a>
              <a href='https://vk.com/missis_laser'>
                <FaVk size={28} color='#0077ff' />
              </a>
              <a href='https://www.instagram.com/missis_laser/'>
                <img alt='inst' src='inst.png' style={{ width: '24px' }} />
              </a>
              <a href='https://dzen.ru/missis-laser.ru'>
                <img alt='dzen' src='dzen.png' style={{ width: '24px' }} />
              </a>
            </div>
            <Link to={`${Routes.Preparation}`}>
              <ChakraButton size='sm' colorScheme='green' /* leftIcon={<FaInstagram />} */>
                Как подготовится к процедуре?
              </ChakraButton>
            </Link>
          </div>
        }
      />
    </Box>
  );
};
