export const moscow_companies = [
  /* {
    id: 146058,
    title: "Кто такая (м.Автозаводская) ул. Ленинская Слобода, 26",
    public_title: 'Сеть салонов красоты "Кто такая"',
    short_descr: "Салон красоты",
    logo: "https://assets.yclients.com/general/c/cf/cf1361b6c06df07_20210406171934.png",
    country_id: 1,
    country: "Россия",
    city_id: 2,
    city: "Москва",
    active: 1,
    phone: "+7 903 770-99-96",
    phones: ["+7 903 770-99-96", "+7 495 133-16-29"],
    email: "",
    timezone: 3,
    timezone_name: "Europe/Moscow",
    schedule: "10:00-22:00",
    address: "ул. Ленинская Слобода, 26",
    coordinate_lat: 55.704582,
    coordinate_lon: 37.646413,
    app_ios: "",
    app_android: "",
    phone_confirmation: false,
    currency_short_title: "₽",
    reminds_sms_disabled: false,
    reminds_sms_default: 3,
    group_priority: 1000,
    bookform_group_priority: 1000,
    description: "",
    photos: [],
    company_photos: [],
    seance_delay_step: 0,
    show_any_master: true,
    allow_delete_record: true,
    allow_change_record: true,
    allow_change_record_delay_step: 0,
    allow_delete_record_delay_step: 0,
    timetable_off: false,
    site: "www.instagram.com/kto_takaya_studio/",
    zip: 115280,
    business_group_id: 1,
    business_type_id: 1,
    is_charge_active: false,
    is_charge_optional: false,
    is_abonement_restriction_enabled: false,
    print_bill_on: 0,
    print_bill_type: "",
    record_type_id: 1,
    split_records_by_resources: 0,
    auto_pay_account_id: 260772,
    auto_pay_bank_account_id: 260773,
    is_admin_app: 0,
    push_notification_phone_confirm: 1,
    switched_to_tariff: true,
    sms_enabled: true,
    activity_record_clients_count_max: 1,
    activity_online_record_clients_count_max: 1,
    is_individual: false,
    is_tips_enabled: true,
    email_hours_default: 3,
    is_show_privacy_policy: true,
    is_show_timetable_record_master_resources: false,
    social: {
      facebook: "",
      vk: "",
      instagram: "",
      telegram: "",
      whatsapp: "+79037709996",
      viber: "",
    },
    main_group_id: 125985,
    main_group: {
      id: 125985,
      title: "Сеть салонов красоты",
    },
    active_staff_count: 19,
    next_slot: "2022-11-12T18:35:00+0300",
    booking_comment_input_name: "Промокод (если есть)",
    booking_comment_required: false,
    booking_email_required: false,
    booking_comment_hidden: false,
    booking_email_hidden: false,
    booking_promo_link_url: null,
  }, */
  {
    id: 387989,
    title: "Миссис Лазер (Петровка) метро Трубная, ул. Петровка, 26, стр. 3",
    public_title: "Миссис Лазер (Петровка)",
    short_descr: "Клиника лазерной эпиляции",
    logo: "https://assets.yclients.com/general/7/77/773333e9e0f38d4_20200929181434.png",
    country_id: 1,
    country: "Россия",
    city_id: 2,
    city: "Москва",
    active: 1,
    phone: "+7 495 128-06-13",
    phones: ["+7 495 128-06-13", "+7 800 551-52-57"],
    email: "inokewm@mail.ru",
    timezone: 3,
    timezone_name: "Europe/Moscow",
    schedule: "пн.-вс.:10:00-22:00",
    address: "Петровка ул., 26 строение 3",
    coordinate_lat: 55.765948,
    coordinate_lon: 37.615863,
    app_ios: "",
    app_android: "",
    phone_confirmation: false,
    currency_short_title: "₽",
    reminds_sms_disabled: false,
    reminds_sms_default: 24,
    group_priority: 987,
    bookform_group_priority: 987,
    description: "",
    photos: [],
    company_photos: [],
    seance_delay_step: 0,
    show_any_master: true,
    allow_delete_record: true,
    allow_change_record: true,
    allow_change_record_delay_step: 0,
    allow_delete_record_delay_step: 0,
    timetable_off: false,
    site: "www.instagram.com/missis_laser/",
    zip: 127051,
    business_group_id: 1,
    business_type_id: 1,
    is_charge_active: true,
    is_abonement_restriction_enabled: false,
    print_bill_on: 1,
    print_bill_type: "yclients_kkm",
    record_type_id: 1,
    split_records_by_resources: 0,
    auto_pay_account_id: 768719,
    auto_pay_bank_account_id: 768720,
    is_admin_app: 0,
    push_notification_phone_confirm: 1,
    switched_to_tariff: true,
    sms_enabled: true,
    activity_record_clients_count_max: 1,
    activity_online_record_clients_count_max: 1,
    is_individual: false,
    is_tips_enabled: true,
    email_hours_default: 24,
    is_show_privacy_policy: false,
    is_show_timetable_record_master_resources: false,
    is_switched_to_ag: false,
    social: {
      facebook: "",
      vk: "",
      instagram: "",
      telegram: "",
      whatsapp: "",
      viber: "",
    },
    main_group_id: 211908,
    main_group: {
      id: 211908,
      title: "Сеть Миссис Лазер Основная",
    },
    active_staff_count: 13,
    next_slot: "2022-07-29T10:00:00+0300",
    booking_comment_input_name: "Комментарий к записи",
    booking_comment_required: false,
    booking_email_required: false,
    booking_promo_link_url: null,
  },
  {
    id: 430265,
    title: "Миссис лазер (Сайкина) метро Технопарк, ул. Сайкина, 19",
    public_title: "Миссис лазер (Сайкина)",
    short_descr: "Клиника",
    logo: "https://assets.yclients.com/general/5/5a/5ac46538fb8b044_20210728163626.png",
    country_id: 1,
    country: "Россия",
    city_id: 2,
    city: "Москва",
    active: 1,
    phone: "+7 495 266-68-27",
    phones: ["+7 495 266-68-27"],
    email: "t_zubkova1968@mail.ru",
    timezone: 3,
    timezone_name: "Europe/Moscow",
    schedule: "пн-вс:10:00 -22:00",
    address: "Сайкина ул., 19",
    coordinate_lat: 55.704183,
    coordinate_lon: 37.668725,
    app_ios: "",
    app_android: "",
    phone_confirmation: false,
    currency_short_title: "₽",
    reminds_sms_disabled: false,
    reminds_sms_default: 24,
    group_priority: 986,
    bookform_group_priority: 986,
    description: "",
    photos: [],
    company_photos: [],
    seance_delay_step: 0,
    show_any_master: true,
    allow_delete_record: true,
    allow_change_record: true,
    allow_change_record_delay_step: 0,
    allow_delete_record_delay_step: 0,
    timetable_off: false,
    site: "www.instagram.com/missis_laser/",
    zip: 115432,
    business_group_id: 1,
    business_type_id: 101,
    is_charge_active: true,
    is_abonement_restriction_enabled: false,
    print_bill_on: 1,
    print_bill_type: "yclients_kkm",
    record_type_id: 1,
    split_records_by_resources: 0,
    auto_pay_account_id: 864575,
    auto_pay_bank_account_id: 864576,
    is_admin_app: 0,
    push_notification_phone_confirm: 1,
    switched_to_tariff: true,
    sms_enabled: true,
    activity_record_clients_count_max: 1,
    activity_online_record_clients_count_max: 1,
    is_individual: false,
    is_tips_enabled: true,
    email_hours_default: 24,
    is_show_privacy_policy: false,
    is_show_timetable_record_master_resources: false,
    is_switched_to_ag: false,
    social: {
      facebook: "",
      vk: "",
      instagram: "",
      telegram: "",
      whatsapp: "",
      viber: "",
    },
    main_group_id: 125985,
    main_group: {
      id: 125985,
      title: "Сеть салонов красоты",
    },
    active_staff_count: 7,
    next_slot: "2022-07-29T10:00:00+0300",
    booking_comment_input_name: "Комментарий к записи",
    booking_comment_required: false,
    booking_email_required: false,
    booking_promo_link_url: null,
  },
  {
    id: 24642,
    title: "Миссис Лазер (м.Тверская) ул. Большая Никитская, 21/18, стр. 1",
    public_title: "Миссис Лазер (м.Тверская)",
    short_descr: "Клиника лазерной эпиляции",
    logo: "https://assets.yclients.com/general/a/a7/a7a98d25358f235_20191014154510.png",
    country_id: 1,
    country: "Россия",
    city_id: 2,
    city: "Москва",
    active: 1,
    phone: "+7 495 266-07-82",
    phones: ["+7 495 266-07-82", "+7 800 551-52-57"],
    email: "essencerose@mail.ru",
    timezone: 3,
    timezone_name: "Europe/Moscow",
    schedule: "пн.-вс.: 10:00-22:00",
    address: "г. Москва, ул. Большая Никитская, д. 21/18, стр. 1",
    coordinate_lat: 55.757232,
    coordinate_lon: 37.60034,
    app_ios: "",
    app_android: "",
    phone_confirmation: false,
    currency_short_title: "₽",
    reminds_sms_disabled: false,
    reminds_sms_default: 24,
    group_priority: 988,
    bookform_group_priority: 988,
    description: "<div><br /></div><div><br /></div>",
    photos: [],
    company_photos: [],
    seance_delay_step: 0,
    show_any_master: true,
    allow_delete_record: true,
    allow_change_record: true,
    allow_change_record_delay_step: 0,
    allow_delete_record_delay_step: 0,
    timetable_off: false,
    site: "www.instagram.com/missis_laser?igshid=1ad2lcn5cqdst",
    zip: 125009,
    business_group_id: 1,
    business_type_id: 1,
    is_charge_active: true,
    is_abonement_restriction_enabled: false,
    print_bill_on: 0,
    print_bill_type: "",
    record_type_id: 1,
    split_records_by_resources: 0,
    auto_pay_account_id: 19189,
    auto_pay_bank_account_id: 19190,
    is_admin_app: 0,
    push_notification_phone_confirm: 1,
    switched_to_tariff: true,
    sms_enabled: true,
    activity_record_clients_count_max: 1,
    activity_online_record_clients_count_max: 1,
    is_individual: false,
    is_tips_enabled: true,
    email_hours_default: 24,
    is_show_privacy_policy: false,
    is_show_timetable_record_master_resources: false,
    is_switched_to_ag: false,
    social: {
      facebook: "",
      vk: "",
      instagram: "",
      telegram: "",
      whatsapp: "",
      viber: "",
    },
    main_group_id: 211908,
    main_group: {
      id: 211908,
      title: "Сеть Миссис Лазер Основная",
    },
    active_staff_count: 15,
    next_slot: "2022-07-29T10:40:00+0300",
    booking_comment_input_name: "Промокод (если есть)",
    booking_comment_required: false,
    booking_email_required: false,
    booking_promo_link_url: null,
  },
];

export const alex_companies = [
  {
    id: 24642,
    title: "Миссис Лазер (м.Тверская) ул. Большая Никитская, 21/18, стр. 1",
    public_title: "Миссис Лазер (м.Тверская)",
    short_descr: "Клиника лазерной эпиляции",
    logo: "https://assets.yclients.com/general/a/a7/a7a98d25358f235_20191014154510.png",
    country_id: 1,
    country: "Россия",
    city_id: 2,
    city: "Москва",
    active: 1,
    phone: "+7 495 266-07-82",
    phones: ["+7 495 266-07-82", "+7 800 551-52-57"],
    email: "",
    timezone: 3,
    timezone_name: "Europe/Moscow",
    schedule: "пн.-вс.: 10:00-22:00",
    address: "г. Москва, ул. Большая Никитская, д. 21/18, стр. 1",
    coordinate_lat: 55.757232,
    coordinate_lon: 37.60034,
    app_ios: "",
    app_android: "",
    phone_confirmation: false,
    currency_short_title: "₽",
    reminds_sms_disabled: false,
    reminds_sms_default: 24,
    group_priority: 988,
    bookform_group_priority: 988,
    description: "<div><br /></div><div><br /></div>",
    photos: [],
    company_photos: [],
    seance_delay_step: 0,
    show_any_master: true,
    allow_delete_record: true,
    allow_change_record: true,
    allow_change_record_delay_step: 0,
    allow_delete_record_delay_step: 0,
    timetable_off: false,
    site: "www.instagram.com/missis_laser?igshid=1ad2lcn5cqdst",
    zip: 125009,
    business_group_id: 1,
    business_type_id: 1,
    is_charge_active: true,
    is_charge_optional: false,
    is_abonement_restriction_enabled: false,
    print_bill_on: 0,
    print_bill_type: "",
    record_type_id: 1,
    split_records_by_resources: 0,
    auto_pay_account_id: 19189,
    auto_pay_bank_account_id: 19190,
    is_admin_app: 0,
    push_notification_phone_confirm: 1,
    switched_to_tariff: true,
    sms_enabled: true,
    activity_record_clients_count_max: 1,
    activity_online_record_clients_count_max: 1,
    is_individual: false,
    is_tips_enabled: true,
    email_hours_default: 24,
    is_show_privacy_policy: false,
    is_show_timetable_record_master_resources: false,
    social: {
      facebook: "",
      vk: "",
      instagram: "",
      telegram: "",
      whatsapp: "",
      viber: "",
    },
    main_group_id: 211908,
    main_group: {
      id: 211908,
      title: "Сеть Миссис Лазер Основная",
    },
    active_staff_count: 15,
    next_slot: "2022-11-14T13:45:00+0300",
    booking_comment_input_name: "Промокод (если есть)",
    booking_comment_required: false,
    booking_email_required: false,
    booking_comment_hidden: false,
    booking_email_hidden: false,
    booking_promo_link_url: null,
  },
  {
    id: 291422,
    title: "Миссис Лазер (Казань) пр-т Победы 139, корп 2",
    public_title: "Миссис Лазер (пр-т Победы 139, корп 2)",
    short_descr: "Клиника лазерной эпиляции",
    logo: "https://assets.yclients.com/general/6/66/66f1130ff04abcb_20191229134603.png",
    country_id: 1,
    country: "Россия",
    city_id: 3,
    city: "Казань",
    active: 1,
    phone: "+7 843 207-14-08",
    phones: ["+7 843 207-14-08", "+7 800 551-52-57"],
    email: "",
    timezone: 3,
    timezone_name: "Europe/Moscow",
    schedule: "10:00-22:00",
    address: "просп. Победы, 139 корпус 2",
    coordinate_lat: 55.775797,
    coordinate_lon: 49.218363,
    app_ios: "",
    app_android: "",
    phone_confirmation: false,
    currency_short_title: "₽",
    reminds_sms_disabled: false,
    reminds_sms_default: 24,
    group_priority: 985,
    bookform_group_priority: 985,
    description: "",
    photos: [],
    company_photos: [],
    seance_delay_step: 0,
    show_any_master: true,
    allow_delete_record: true,
    allow_change_record: true,
    allow_change_record_delay_step: 0,
    allow_delete_record_delay_step: 0,
    timetable_off: false,
    site: "instagram.com/missis_laser?igshid=1pompmn2807bd",
    zip: 420100,
    business_group_id: 1,
    business_type_id: 35,
    is_charge_active: true,
    is_charge_optional: false,
    is_abonement_restriction_enabled: false,
    print_bill_on: 1,
    print_bill_type: "yclients_kkm",
    record_type_id: 1,
    split_records_by_resources: 0,
    auto_pay_account_id: 561998,
    auto_pay_bank_account_id: 561999,
    is_admin_app: 0,
    push_notification_phone_confirm: 1,
    switched_to_tariff: true,
    sms_enabled: false,
    activity_record_clients_count_max: 1,
    activity_online_record_clients_count_max: 1,
    is_individual: false,
    is_tips_enabled: true,
    email_hours_default: 24,
    is_show_privacy_policy: false,
    is_show_timetable_record_master_resources: false,
    social: {
      facebook: "",
      vk: "",
      instagram: "",
      telegram: "",
      whatsapp: "",
      viber: "",
    },
    main_group_id: 211908,
    main_group: {
      id: 211908,
      title: "Сеть Миссис Лазер Основная",
    },
    active_staff_count: 6,
    next_slot: "2022-11-14T13:45:00+0300",
    booking_comment_required: false,
    booking_email_required: false,
    booking_comment_hidden: false,
    booking_email_hidden: false,
    booking_promo_link_url: null,
  },
  {
    id: 341734,
    title: "Миссис Лазер (м. Горьковская)  СПб, ул. Большая Посадская, 12",
    public_title: "Миссис Лазер  (м. Горьковская)",
    short_descr: "Сеть клиник  лазерной эпиляции",
    logo: "https://assets.yclients.com/general/c/cb/cb4a83f253c59a8_20210728163455.png",
    country_id: 1,
    country: "Россия",
    city_id: 1,
    city: "Санкт-Петербург",
    active: 1,
    phone: "+7 812 509-13-39",
    phones: ["+7 812 509-13-39", "+7 800 551-52-57"],
    email: "",
    timezone: 3,
    timezone_name: "Europe/Moscow",
    schedule: "пн-вс:10:00 -22:00",
    address: "Большая Посадская ул., 12",
    coordinate_lat: 59.958787,
    coordinate_lon: 30.324357,
    app_ios: "",
    app_android: "",
    phone_confirmation: false,
    currency_short_title: "₽",
    reminds_sms_disabled: false,
    reminds_sms_default: 24,
    group_priority: 989,
    bookform_group_priority: 989,
    description: "",
    photos: [],
    company_photos: [],
    seance_delay_step: 0,
    show_any_master: true,
    allow_delete_record: true,
    allow_change_record: true,
    allow_change_record_delay_step: 0,
    allow_delete_record_delay_step: 0,
    timetable_off: false,
    site: "www.instagram.com/missis_laser/",
    zip: 197046,
    business_group_id: 1,
    business_type_id: 35,
    is_charge_active: true,
    is_charge_optional: false,
    is_abonement_restriction_enabled: false,
    print_bill_on: 1,
    print_bill_type: "yclients_kkm",
    record_type_id: 1,
    split_records_by_resources: 0,
    auto_pay_account_id: 666995,
    auto_pay_bank_account_id: 666996,
    is_admin_app: 0,
    push_notification_phone_confirm: 1,
    switched_to_tariff: true,
    sms_enabled: true,
    activity_record_clients_count_max: 1,
    activity_online_record_clients_count_max: 1,
    is_individual: false,
    is_tips_enabled: true,
    email_hours_default: 24,
    is_show_privacy_policy: false,
    is_show_timetable_record_master_resources: false,
    social: {
      facebook: "",
      vk: "",
      instagram: "",
      telegram: "",
      whatsapp: "",
      viber: "",
    },
    main_group_id: 211908,
    main_group: {
      id: 211908,
      title: "Сеть Миссис Лазер Основная",
    },
    active_staff_count: 8,
    next_slot: "2022-11-14T15:10:00+0300",
    booking_comment_required: false,
    booking_email_required: false,
    booking_comment_hidden: false,
    booking_email_hidden: false,
    booking_promo_link_url: null,
  },
  {
    id: 387989,
    title: "Миссис Лазер (Петровка) метро Трубная, ул. Петровка, 26, стр. 3",
    public_title: "Миссис Лазер (Петровка)",
    short_descr: "Клиника лазерной эпиляции",
    logo: "https://assets.yclients.com/general/7/77/773333e9e0f38d4_20200929181434.png",
    country_id: 1,
    country: "Россия",
    city_id: 2,
    city: "Москва",
    active: 1,
    phone: "+7 495 128-06-13",
    phones: ["+7 495 128-06-13", "+7 800 551-52-57"],
    email: "",
    timezone: 3,
    timezone_name: "Europe/Moscow",
    schedule: "пн.-вс.:10:00-22:00",
    address: "Петровка ул., 26 строение 3",
    coordinate_lat: 55.765948,
    coordinate_lon: 37.615863,
    app_ios: "",
    app_android: "",
    phone_confirmation: false,
    currency_short_title: "₽",
    reminds_sms_disabled: false,
    reminds_sms_default: 24,
    group_priority: 987,
    bookform_group_priority: 987,
    description: "",
    photos: [],
    company_photos: [],
    seance_delay_step: 0,
    show_any_master: true,
    allow_delete_record: true,
    allow_change_record: true,
    allow_change_record_delay_step: 0,
    allow_delete_record_delay_step: 0,
    timetable_off: false,
    site: "www.instagram.com/missis_laser/",
    zip: 127051,
    business_group_id: 1,
    business_type_id: 1,
    is_charge_active: true,
    is_charge_optional: false,
    is_abonement_restriction_enabled: false,
    print_bill_on: 1,
    print_bill_type: "yclients_kkm",
    record_type_id: 1,
    split_records_by_resources: 0,
    auto_pay_account_id: 768719,
    auto_pay_bank_account_id: 768720,
    is_admin_app: 0,
    push_notification_phone_confirm: 1,
    switched_to_tariff: true,
    sms_enabled: true,
    activity_record_clients_count_max: 1,
    activity_online_record_clients_count_max: 1,
    is_individual: false,
    is_tips_enabled: true,
    email_hours_default: 24,
    is_show_privacy_policy: false,
    is_show_timetable_record_master_resources: false,
    social: {
      facebook: "",
      vk: "",
      instagram: "",
      telegram: "",
      whatsapp: "",
      viber: "",
    },
    main_group_id: 211908,
    main_group: {
      id: 211908,
      title: "Сеть Миссис Лазер Основная",
    },
    active_staff_count: 13,
    next_slot: "2022-11-14T13:45:00+0300",
    booking_comment_input_name: "Комментарий к записи",
    booking_comment_required: false,
    booking_email_required: false,
    booking_comment_hidden: false,
    booking_email_hidden: false,
    booking_promo_link_url: null,
  },
];
