import { action, makeObservable, observable, runInAction } from "mobx";

import { bookAPI } from "../shared/api";
import { DatesResponse, TimesResponse } from "../shared/api/book";
import { Maybe } from "../types/utils/monads";
import { BaseStore } from "./lib/baseStore.type";

import moment, { Moment } from "moment";

//! Активация Акции «ТЕСТ ДРАЙВ»
//! 1. Выставить правильно даты внутри виджета онлайн записи.
//! 2. Установить период действия услуг для онлайн записи внутри каждой клиники
//* Ответственность на тебе, если услуги не будут включены в ЮКЛ
//! 3. Убедится что для услуг указаны специалисты

//! Деактивация Акции «ТЕСТ ДРАЙВ»
//! 1. Проверить, чтобы эти услуги были недоступны для онлайн записи

const monthDays = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
];

class WorkTimes implements BaseStore {
  @observable selectedDay: Moment = moment();
  @observable bookingDays: DatesResponse["booking_days"] = {
    1: monthDays,
    2: monthDays,
    3: monthDays,
    4: monthDays,
    5: monthDays,
    6: monthDays,
    7: monthDays,
    8: monthDays,
    9: monthDays,
    10: monthDays,
    11: monthDays,
    12: monthDays,
  }; // need change
  @observable isLoading = false;
  @observable timesLoading = false;
  @observable workingTimes: Maybe<TimesResponse[]> = null;
  @observable selectedTime: string | null = null;

  constructor() {
    makeObservable(this);
  }

  @action
  setSelectedDay(date: Moment) {
    this.selectedDay = date;
  }

  @action
  async fetchWorkTimes(companyId: number, selectedServices?: string) {
    const [month, day] = moment().format("M:D").split(":");
    if ((this.bookingDays[Number(month)] ?? []).includes(Number(day))) {
      try {
        this.setTimesLoading(true);
        const date = this.selectedDay.clone();
        const { data } = await bookAPI.getTimesByStaffId({
          companyId,
          selectedServices: selectedServices ? [selectedServices] : undefined,
          date: date.toISOString(),
        });
        runInAction(() => (this.workingTimes = data.data));
      } catch (error) {
      } finally {
        this.setTimesLoading(false);
      }
    } else {
      this.workingTimes = [];
    }
  }

  @action
  setTimesLoading(value: boolean) {
    this.timesLoading = value;
  }

  @action
  setLoading(value: boolean) {
    this.isLoading = value;
  }

  @action
  setSelectedTime(time: string) {
    this.selectedTime = time;
  }

  @action
  resetStore() {
    this.selectedDay = moment();
    // this.bookingDays = {};
    this.selectedTime = null;
    this.workingTimes = null;
    this.workingTimes = null;
  }
}

export const workTimes = new WorkTimes();
