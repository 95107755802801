import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { observer } from 'mobx-react-lite';
import { Routes } from '../../shared/enums/routes';
import { AppTemplate } from '../../shared/ui/AppTemplate';
import { useAsyncFn } from 'react-use';
import { Card, List } from 'antd';
import { Button as ChakraButton } from '@chakra-ui/react';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
  StatLabel,
  Select,
} from '@chakra-ui/react';
import { useCookies } from 'react-cookie';
import { getUsersCount, sendLeadToVakas, checkPhoneNumber } from '../../shared/api/book';
import { AppTemplate1 } from '../../shared/ui/AppTemplate1';
import { Typography } from 'antd';
import { phonesToLead } from './phones';

export const variants = [
  {
    title: 'Диодный лазер\nзона за  500 руб',
    id: 'diod',
    cost: 500,
  },
  {
    title: 'Александритовый лазер\nзона за  700 руб',
    id: 'alex',
    cost: 700,
  },
];

function isNumeric(value: string) {
  if (value === '') {
    return true;
  }

  return /^-?\d+$/.test(value);
}

const getQueryParams = () => {
  const search = window.location.search;
  const searchParams = new URLSearchParams(search);
  const keys = Array.from(searchParams.keys());
  return keys.map(x => ({ [`${x}`]: searchParams.get(x) }));
};

export const Home = observer(() => {
  const [qookies, setCookie] = useCookies(['count']);

  const [error, setError] = useState<boolean>();
  const [errorText, setErrorText] = useState<string | null>(null);
  //* ==================
  const [username, setUserName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  //* ==================
  const [laser, setLaser] = useState<string>('Любаязона500/700ОП');
  const queryParams = getQueryParams();
  const history = useHistory();

  /*   const [state, getCount] = useAsyncFn(async () => {
    return await getUsersCount();
  }); */

  //const num = qookies.count ?? state.value?.data.count;

  const checkPhone = async (phone: string) => {
    return await checkPhoneNumber({
      phone,
    });
  };

  /* useEffect(() => {
    if (qookies.count) return;
    if (!state.value?.data.count) {
      getCount();
      return;
    }

    setCookie('count', state.value?.data.count, {
      expires: new Date(2024, 11, 30),
    });
  }, [qookies.count, state.value?.data?.count]); */

  //console.log('object', num);

  const [state, onSubmit] = useAsyncFn(
    async (data: any, idx: number) => {
      /* const visitCount = await checkPhone(phone);
    if (visitCount.data.count > 0) {
      setError(true);
      setErrorText('По указанному номеру визит уже был');
      return;
    } else { */
      try {
        await sendLeadToVakas({
          name: data.fullName,
          phone: '+' + data.phone.toString(),
          utm_source: '8888.missis-laser.ru',
        }).then(async () => {
          console.log(idx, data.phone, 'SUCCESS');
          // history.push(Routes.Success11);
        });
      } catch (error) {
        console.log(idx, data.phone, 'ERROR');
        console.log(error);
        // history.push(Routes.Error);
      }
      /* } */
    },
    [username, phone, queryParams, window.location.hostname, laser]
  );

  const phones = phonesToLead;

  useEffect(() => {
    phones.forEach((x, idx) => {
      setTimeout(() => {
        onSubmit(x, idx);
      }, 700 * idx);
    });
  }, [phones]);

  /* const onSubmit = async () => {
    const visitCount = await checkPhone(phone);
    if (visitCount.data.count > 0) {
      setError(true);
      setErrorText('По указанному номеру визит уже был');
      return;
    } else {
      try {
        await sendLeadToVakas({
          name: username,
          phone: '+7' + phone,
          laser,
          utm_source: window.location.hostname,
          ...queryParams,
        }).then(async ({ data }) => {
          history.push(Routes.Success11);
        });
      } catch (error) {
        console.log(error);
        history.push(Routes.Error);
      }
    }
  };
 */
  return (
    <AppTemplate1
    //headerTitle='Тест драйв'
    // headerBody={<StatLabel style={{ textAlign: 'center', fontSize: 15 }}></StatLabel>}
    //headerBody={`Акция для новых клиентов только в Январе!\nЛазерная эпиляция зон Бикини + Подмышки за 990 руб вместо 2300 руб! Пока есть возможность - оставляйте заявку и приходите на процедуру! Ждём Вас!`}
    >
      <Box
        p={0}
        overflowY='auto'
        flexGrow={1}
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Stack spacing='2rem'>
          <Stack spacing='1rem'>
            <Typography.Title level={5} style={{ textAlign: 'center' }}>
              Ваши контактные данные
            </Typography.Title>
            <InputGroup>
              <Input
                maxLength={30}
                /* isInvalid={error} */
                placeholder='Введите ваше имя'
                value={username}
                onChange={e => {
                  setUserName(e.target.value);
                  setError(false);
                }}
              />
            </InputGroup>
            <InputGroup>
              <InputLeftAddon children='+7' />
              <Input
                maxLength={10}
                type='tel'
                /* isInvalid={error} */
                placeholder='Введите ваш телефон'
                value={phone}
                onChange={event => {
                  isNumeric(event.target.value) && setPhone(event?.target.value);
                  setError(false);
                }}
              />
            </InputGroup>
            {/* <InputGroup>
              <Select
                value={laser}
                onChange={event => {
                  setLaser(event?.target.value);
                  setError(false);
                }}
                placeholder='Выберите лазер'
              >
                <option value='Любаязона500ОП'>Диодный</option>
                <option value='Любаязона700ОП'>Александритовый</option>
              </Select>
            </InputGroup> */}
          </Stack>
          {error && (
            <Stack spacing='1rem' style={{ marginTop: 12 }}>
              {errorText && (
                <Alert status='error'>
                  <AlertIcon />
                  {errorText}
                </Alert>
              )}
            </Stack>
          )}

          <Button
            isLoading={state.loading}
            onClick={() => {
              if (laser === '' || phone === '' || username === '') {
                setError(true);
                setErrorText(
                  laser === ''
                    ? 'Вы не указали тип лазера'
                    : phone === ''
                    ? 'Вы не указали свой номер'
                    : username === ''
                    ? 'Вы не указали своё имя'
                    : null
                );
              } else {
                onSubmit('', 0);
              }
            }}
            mt='24px'
            w='100%'
            h='60px'
            alignSelf='center'
            colorScheme='teal'
            variant='solid'
          >
            Хочу участвовать
            <br />в акции
          </Button>
          <Typography style={{ textAlign: 'left', fontSize: 15 }}>
            &nbsp;&nbsp; Оставьте заявку и менеджер свяжется с Вами, расскажет всё о процедуре и запишет на удобное для
            Вас время.
            <br />
            &nbsp;&nbsp; Пожалуйста будьте на связи и ответьте на звонок или сообщение в мессенджере от нашего
            сотрудника.
            <br />
            <br />
            <br />
          </Typography>
        </Stack>
      </Box>
    </AppTemplate1>
  );

  /* if (num % 2 === 0) {
    return (
      <AppTemplate1
        headerTitle='Тест драйв'
        headerBody={<StatLabel style={{ textAlign: 'center', fontSize: 15 }}></StatLabel>}
        //headerBody={`Акция для новых клиентов только в Январе!\nЛазерная эпиляция зон Бикини + Подмышки за 990 руб вместо 2300 руб! Пока есть возможность - оставляйте заявку и приходите на процедуру! Ждём Вас!`}
      >
        <Box
          p={0}
          overflowY='auto'
          flexGrow={1}
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          <Stack spacing='2rem'>
            <Stack spacing='1rem'>
              <Typography.Title level={5} style={{ textAlign: 'center' }}>
                Ваши контактные данные
              </Typography.Title>
              <InputGroup>
                <Input
                  maxLength={30}
                  placeholder='Введите ваше имя'
                  value={username}
                  onChange={e => {
                    setUserName(e.target.value);
                    setError(false);
                  }}
                />
              </InputGroup>
              <InputGroup>
                <InputLeftAddon children='+7' />
                <Input
                  maxLength={10}
                  type='tel'
                  placeholder='Введите ваш телефон'
                  value={phone}
                  onChange={event => {
                    isNumeric(event.target.value) && setPhone(event?.target.value);
                    setError(false);
                  }}
                />
              </InputGroup>
            </Stack>
            {error && (
              <Stack spacing='1rem' style={{ marginTop: 12 }}>
                {errorText && (
                  <Alert status='error'>
                    <AlertIcon />
                    {errorText}
                  </Alert>
                )}
              </Stack>
            )}

            <Button
              onClick={() => {
                if (laser === '' || phone === '' || username === '') {
                  setError(true);
                  setErrorText(
                    laser === ''
                      ? 'Вы не указали тип лазера'
                      : phone === ''
                      ? 'Вы не указали свой номер'
                      : username === ''
                      ? 'Вы не указали своё имя'
                      : null
                  );
                } else {
                  onSubmit();
                }
              }}
              mt='24px'
              w='100%'
              h='60px'
              alignSelf='center'
              colorScheme='teal'
              variant='solid'
            >
              Хочу участвовать
              <br />в акции
            </Button>
            <Typography style={{ textAlign: 'left', fontSize: 15 }}>
              &nbsp;&nbsp; Оставьте заявку и менеджер свяжется с Вами, расскажет всё о процедуре и запишет на удобное
              для Вас время.
              <br />
              &nbsp;&nbsp; Пожалуйста будьте на связи и ответьте на звонок или сообщение в мессенджере от нашего
              сотрудника.
              <br />
              <br />
              <br />
            </Typography>
          </Stack>
        </Box>
      </AppTemplate1>
    );
  } else {
    return (
      <AppTemplate1>
        <Box p={0} overflowY='auto' flexGrow={1} style={{ marginTop: '24px', marginBottom: '36px', width: '100%' }}>
          <List
            itemLayout='horizontal'
            dataSource={variants}
            style={{ width: '100%' }}
            renderItem={(item, index) => (
              <Link key={index} to={`${Routes.Welcome}/${item.id}`} style={{ width: '100%', display: 'block' }}>
                <Card id='ant-card-body' hoverable style={{ width: '100%' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <div>
                      {item.title.split('\n').map((str, subindex) => (
                        <Card.Meta key={`${index}-${subindex}`} title={str} />
                      ))}
                    </div>
                    <ChakraButton borderRadius={12} size='sm' backgroundColor={'#b1fafb'}>
                      Выбрать
                    </ChakraButton>
                  </div>
                </Card>
              </Link>
            )}
          />
          <Typography style={{ textAlign: 'left', fontSize: 15, marginTop: '24px' }}>
            <b>Отличия диодного лазера:</b> <br />
            &nbsp; 👉 Безболезненный; <br />
            &nbsp; 👉 Хорошее охлаждение; <br />
            &nbsp; 👉 Результат после 1-й процедуры, нужно в среднем 8-12 процедур; <br />
            &nbsp; 👉 Лучше работает с тёмными волосами, не работает для очень светлых волос, седых, пушковых, рыжих;{' '}
            <br />
            &nbsp; 👉 Более выгодный (цена за процедуру меньше); <br />
            &nbsp; 👉 Есть в каждой клинике нашей сети: Москва, Санкт-Петербург, Казань, Тверь, Уфа. <br /> <br />
            <b>Отличия александритового лазера:</b> <br />
            &nbsp; 👉 Немного выше цена, но зато ещё более эффективный и быстрый результат, нужно пройти меньше процедур
            в курсе, около 6-10 процедур; <br />
            &nbsp; 👉 Александритовый лазер работает практически с любыми типами волос (кроме седых, пушковых, рыжих);{' '}
            <br />
            &nbsp; 👉 Чувствительность при процедуре не много выше чем на диодном; <br />
            &nbsp; 👉 Есть только в городах: Москва, Санкт-Петербург, Казань. <br /> <br />
            <Typography style={{ textAlign: 'center', fontSize: 15 }}>
              Важно❗️
              <br /> Мы используем только самое топовое, мощное и сертифицированное оборудование для обоих типов лазеров
              (Диодного и Александритового) ❗️
            </Typography>
            <br />
            <br />
          </Typography>
        </Box>
        <div style={{ display: 'flex', flexGrow: 1, flexShrink: 1, flexBasis: 0 }}></div>
        <Box display='flex' justifyContent='center' style={{ marginTop: 'auto' }}>
          Есть вопросы? <br />
        </Box>
        <Box p={2} display='flex' justifyContent='center'>
          <a
            id='whatsapp_widget'
            target='_blank'
            rel='noreferrer'
            href='https://wa.me/79315878205?text=Привет, у меня вопрос по акции любая зона за 600 руб'
          >
            <span>Напишите нам в WhatsApp</span>
            <svg
              version='1.1'
              id='whatsapp'
              xmlns='http://www.w3.org/2000/svg'
              x='0px'
              y='0px'
              viewBox='0 0 30.667 30.667'
            >
              <path d='M30.667,14.939c0,8.25-6.74,14.938-15.056,14.938c-2.639,0-5.118-0.675-7.276-1.857L0,30.667l2.717-8.017 c-1.37-2.25-2.159-4.892-2.159-7.712C0.559,6.688,7.297,0,15.613,0C23.928,0.002,30.667,6.689,30.667,14.939z M15.61,2.382 c-6.979,0-12.656,5.634-12.656,12.56c0,2.748,0.896,5.292,2.411,7.362l-1.58,4.663l4.862-1.545c2,1.312,4.393,2.076,6.963,2.076 c6.979,0,12.658-5.633,12.658-12.559C28.27,8.016,22.59,2.382,15.61,2.382z M23.214,18.38c-0.094-0.151-0.34-0.243-0.708-0.427 c-0.367-0.184-2.184-1.069-2.521-1.189c-0.34-0.123-0.586-0.185-0.832,0.182c-0.243,0.367-0.951,1.191-1.168,1.437 c-0.215,0.245-0.43,0.276-0.799,0.095c-0.369-0.186-1.559-0.57-2.969-1.817c-1.097-0.972-1.838-2.169-2.052-2.536 c-0.217-0.366-0.022-0.564,0.161-0.746c0.165-0.165,0.369-0.428,0.554-0.643c0.185-0.213,0.246-0.364,0.369-0.609 c0.121-0.245,0.06-0.458-0.031-0.643c-0.092-0.184-0.829-1.984-1.138-2.717c-0.307-0.732-0.614-0.611-0.83-0.611 c-0.215,0-0.461-0.03-0.707-0.03S9.897,8.215,9.56,8.582s-1.291,1.252-1.291,3.054c0,1.804,1.321,3.543,1.506,3.787 c0.186,0.243,2.554,4.062,6.305,5.528c3.753,1.465,3.753,0.976,4.429,0.914c0.678-0.062,2.184-0.885,2.49-1.739 C23.307,19.268,23.307,18.533,23.214,18.38z'></path>
            </svg>
          </a>
        </Box>
      </AppTemplate1>
    );
  } */
});
