export enum Routes {
  Home = "/",
  Welcome = "/services",
  Error = "/error",
  SelectCity = "/select-city",
  InitService = "/init-service",
  SelectCompany = "/select-company",
  SelectZone = "/select-zone",
  SelectDate = "/select-date",
  SMSConfirm = "/sms-confirm",
  Success = "/success",
  Success11 = "/success-page",
  Page404 = "/404",
  Preparation = "/preparation",
}
