import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { observer } from 'mobx-react-lite';

import { Box } from '@chakra-ui/react';

import { Routes } from '../../shared/enums/routes';
import { AppTemplate } from '../../shared/ui/AppTemplate';

import { Card, List, Spin } from 'antd';
import { Button as ChakraButton } from '@chakra-ui/react';
import { useStore } from '../../stores';

export const variants_diod = [
  {
    title: 'Одна любая зона за \n 500 руб',
    id: '10549216',
    id_kto: '11252881',
    cost: 500,
  },
  {
    title: 'Две любые зоны за \n 1000 руб',
    id: '10549263',
    id_kto: '11252889',
    cost: 1000,
  },
  {
    title: 'Три любые зоны за \n 1500 руб',
    id: '10549275',
    id_kto: '11252896',
    cost: 1500,
  },
  {
    title: 'Четыре любые зоны за \n 2000 руб',
    id: '10549282',
    id_kto: '11252900',
    cost: 2000,
  },
];

export const variants_alex = [
  {
    title: 'Одна любая зона за \n 700 руб',
    id: '11260230',
    id_kto: '11252881',
    cost: 700,
  },
  {
    title: 'Две любые зоны за \n 1400 руб',
    id: '11260233',
    id_kto: '11252889',
    cost: 1400,
  },
  {
    title: 'Три любые зоны за \n 2100 руб',
    id: '11260235',
    id_kto: '11252896',
    cost: 2100,
  },
  {
    title: 'Четыре любые зоны за \n 2800 руб',
    id: '11260246',
    id_kto: '11252900',
    cost: 2800,
  },
];

export const Welcome = observer(() => {
  const { registerStore } = useStore();
  const { formId } = useParams<{ formId: 'diod' | 'alex' }>();
  const [laserType, setLaserType] = useState<'diod' | 'alex' | null>(null);

  useEffect(() => {
    if (formId) {
      setLaserType(formId);
      registerStore.setLaser(formId);
    } else {
      setLaserType('diod');
      registerStore.setLaser('diod');
    }
  }, [formId]);

  console.log(laserType);

  if (!laserType) {
    return <Spin size='large' />;
  }

  const data = laserType === 'diod' ? variants_diod : variants_alex;

  return (
    <AppTemplate headerTitle='Тест драйв' headerBody={`Любая зона за ${data[0].cost} руб`}>
      <Box p={0} overflowY='auto' flexGrow={1}>
        <List
          itemLayout='horizontal'
          dataSource={data}
          renderItem={(item, index) => (
            <Link key={index} to={`${Routes.InitService}/${item.id}`}>
              <Card id='ant-card-body' hoverable>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    {item.title.split('\n').map((str, subindex) => (
                      <Card.Meta key={`${index}-${subindex}`} title={str} />
                    ))}
                  </div>
                  <ChakraButton borderRadius={12} size='sm' backgroundColor={'#b1fafb'}>
                    Выбрать
                  </ChakraButton>
                </div>
              </Card>
            </Link>
          )}
        />
      </Box>
      <Box display='flex' justifyContent='center'>
        Есть вопросы? <br />
      </Box>
      <Box p={2} display='flex' justifyContent='center'>
        <a
          id='whatsapp_widget'
          target='_blank'
          rel='noreferrer'
          href='https://wa.me/79315878205?text=Привет, у меня вопрос по акции любая зона за 500 руб'
        >
          <span>Напишите нам в WhatsApp</span>
          <svg
            version='1.1'
            id='whatsapp'
            xmlns='http://www.w3.org/2000/svg'
            x='0px'
            y='0px'
            viewBox='0 0 30.667 30.667'
          >
            <path d='M30.667,14.939c0,8.25-6.74,14.938-15.056,14.938c-2.639,0-5.118-0.675-7.276-1.857L0,30.667l2.717-8.017 c-1.37-2.25-2.159-4.892-2.159-7.712C0.559,6.688,7.297,0,15.613,0C23.928,0.002,30.667,6.689,30.667,14.939z M15.61,2.382 c-6.979,0-12.656,5.634-12.656,12.56c0,2.748,0.896,5.292,2.411,7.362l-1.58,4.663l4.862-1.545c2,1.312,4.393,2.076,6.963,2.076 c6.979,0,12.658-5.633,12.658-12.559C28.27,8.016,22.59,2.382,15.61,2.382z M23.214,18.38c-0.094-0.151-0.34-0.243-0.708-0.427 c-0.367-0.184-2.184-1.069-2.521-1.189c-0.34-0.123-0.586-0.185-0.832,0.182c-0.243,0.367-0.951,1.191-1.168,1.437 c-0.215,0.245-0.43,0.276-0.799,0.095c-0.369-0.186-1.559-0.57-2.969-1.817c-1.097-0.972-1.838-2.169-2.052-2.536 c-0.217-0.366-0.022-0.564,0.161-0.746c0.165-0.165,0.369-0.428,0.554-0.643c0.185-0.213,0.246-0.364,0.369-0.609 c0.121-0.245,0.06-0.458-0.031-0.643c-0.092-0.184-0.829-1.984-1.138-2.717c-0.307-0.732-0.614-0.611-0.83-0.611 c-0.215,0-0.461-0.03-0.707-0.03S9.897,8.215,9.56,8.582s-1.291,1.252-1.291,3.054c0,1.804,1.321,3.543,1.506,3.787 c0.186,0.243,2.554,4.062,6.305,5.528c3.753,1.465,3.753,0.976,4.429,0.914c0.678-0.062,2.184-0.885,2.49-1.739 C23.307,19.268,23.307,18.533,23.214,18.38z'></path>
          </svg>
        </a>
      </Box>
    </AppTemplate>
  );
});
